import React, { useContext, useEffect } from "react"
import { graphql } from "gatsby"
import { BookingForm } from "../context/context"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HeroSection from "../containers/hero-section-dynamic"
import Instagram from "../containers/instagram"
import ContentBlock from "../containers/content-block"
import Carousel from "../containers/carousel"
import Eat from "../images/eat-eat-eat.png"
import FunPurple from "../images/fun-purple.png"
import ContactUs from "../containers/contact-us"
import ZrceLogoSimple from "../images/zrce-logo-simple.png"
import ContentBlockCabanas from "../containers/content-block-cabanas-left"
import ContentArea from "../dynamic-zone/content-area"

const LaceBeach = ({ data, location }) => {
  const { setBookingForm } = useContext(BookingForm)
  useEffect(() => {
    setBookingForm({ open: false, type: "Deck Chairs and Cabanas", id: 1 })
  }, [])
  const headerList = [
    {
      id: 2,
      eventId: 2,
      name: "Deck Chairs",
      resTime: [
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
      ],
    },
    {
      id: 3,
      eventId: 8,
      name: "Cabanas",
      resTime: [
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
      ],
    },
  ]

  return (
    <Layout
      location={location}
      logo={ZrceLogoSimple}
      color="bg-cabanasBrown-50"
      ctaColor="bg-cabanasBrown-50"
      textCtaColor="text-white"
      onlyMainLogoSimple
      dropdownList={headerList}
      deckChairs={true}
    >
      <Seo title="Home" />
      <HeroSection
        image={
          data.strapiBeach.HeroSectionRestaurants.backgroundImageRestaurant
        }
        smallImage={data.strapiBeach.MobileHeroSection.image}
        sectionType={"restaurant"}
      />

      <ContentArea
        carouselTopImage={FunPurple}
        carouselTopPosition="Left"
        color="bg-cabanasBrown-50"
        hideCtaContentBlock
        contentTypes={data.strapiBeach.pageContent}
        title={data.strapiBeach.title}
        onlyContactUs
        // instaNodes={data.allInstaNode.edges}
      />

      <ContactUs
        color="bg-cabanasBrown-50"
        contactHeaderColor="text-white"
        contactSubHeaderColor="text-white"
        contactContentColor="text-white"
        contactCtaColor="text-white"
        contactCtaBorderColor="border-white"
        onlyContactUs
      />
      {/* <ZrceFun />
      <TwoColumnImages />
      <Restaurants />
      <Cabanas />
      <JetSki /> */}
      {/* <LatestNews /> */}
      {/* <Gallery /> */}
      {/* <Instagram bgColor="#E2C0B3" title={"zrcebeach_official"} /> */}
    </Layout>
  )
}

export default LaceBeach

// export const pageQuery = graphql`
//   query {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//     allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
//       nodes {
//         excerpt
//         fields {
//           slug
//         }
//         frontmatter {
//           date(formatString: "MMMM DD, YYYY")
//           title
//           description
//         }
//       }
//     }
//   }
// `

// export const pageQuery = graphql`
//   query {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//     strapiClub(strapi_id: { eq: 1 }) {
//       id
//       clubName
//       seo {
//         metaTitle
//         metaDescription
//       }
//       galleries {
//         id
//         title
//         images {
//           id
//           localFile {
//             childImageSharp {
//               id
//               gatsbyImageData(quality: 10)
//             }
//           }
//         }
//       }
//       backgroundImageMain {
//         localFile {
//           childImageSharp {
//             gatsbyImageData(placeholder: BLURRED, quality: 90)
//           }
//         }
//       }
//       pageContent {
//         ... on STRAPI__COMPONENT_MAIN_COMPONENTS_EVENTS {
//           id
//           sectionId
//           strapi_component
//         }
//         ... on STRAPI__COMPONENT_MAIN_COMPONENTS_GALLERY_CLUB {
//           id
//           strapi_component
//           cloudText
//           title
//           subtitle
//         }
//       }
//     }
//     allStrapiEvent {
//       edges {
//         node {
//           title
//           dateTimeFrom(formatString: "DD MMM")
//           dateTimeTo(formatString: "DD MMM")
//           image {
//             localFile {
//               childImageSharp {
//                 gatsbyImageData(quality: 20)
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `

export const pageQuery = graphql`
  query zrceBeachFirst {
    strapiBeach(strapi_id: { eq: 2 }) {
      id
      title
      pageContent {
        ... on STRAPI__COMPONENT_MAIN_COMPONENTS_RESTAURANT_CAROUSEL {
          id
          cloudText
          strapi_component
          RestaurantCarouselImage {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80)
                }
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_MAIN_COMPONENTS_RESTAURANT_CONTENT_BLOCK {
          id
          strapi_component
          text
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80)
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_MAIN_COMPONENTS_IMAGE_BLOCK_LEFT {
          id
          linkText
          strapi_component
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_MAIN_COMPONENTS_IMAGE_BLOCK_RIGHT {
          id
          linkText
          strapi_component
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
        }
      }
      HeroSectionRestaurants {
        altTag
        backgroundImageRestaurant {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR)
            }
          }
        }
      }
      MobileHeroSection {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                formats: WEBP
                placeholder: DOMINANT_COLOR
              )
            }
          }
        }
      }
    }
  }
`
